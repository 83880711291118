<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <p
        v-if="lparteTrabajoList.length > 1"
        class="title text-center"
      >
        Material {{ lparteTrabajoList.indexOf(routeParams.idlparte_trabajo) + 1 }} de {{ lparteTrabajoList.length }}
      </p>
      <v-form
        ref="form"
        lazy-validation
      >
        <lparte-trabajo-form
          v-if="lparteTrabajo && lparteTrabajo.lparte_trabajo"
          :id="lparteTrabajo.lparte_trabajo.idlparte_trabajo"
          :idparte-trabajo="parseInt(routeParams.idparte_trabajo)"
          :idarticulo="lparteTrabajo.lparte_trabajo.idarticulo"
          :idsubsis="lparteTrabajo.lparte_trabajo.idsubsis"
          :has-perm-ver-tarifas="hasViewPerm(permissions.articulos.verTarifas)"
          :has-perm-modificar-material-facturable="hasViewPerm(permissions.partes.modificarMaterialFacturable)"
          @change-values="changeFormData"
        />
      </v-form>
      <b10-fab-button
        v-show="lparteTrabajoList.length > 0 && prevEnabled"
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorMaterial(false)"
      />
      <b10-fab-button
        v-show="lparteTrabajoList.length > 0 && nextEnabled"
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorMaterial(true)"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LparteTrabajoForm from '../components/LparteTrabajoForm'
import Data from './LparteTrabajoEditData'
import { toDate } from '@/utils/date'
import { encodeExtraQuery } from '@/utils/router'

export default {
  components: {
    LparteTrabajoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      lparteTrabajo: {},
      lparteTrabajoList: [],
    }
  },
  computed: {
    prevEnabled () {
      const actual = this.lparteTrabajoList.indexOf(this.routeParams.idlparte_trabajo)
      return actual > 0
    },
    nextEnabled () {
      const actual = this.lparteTrabajoList.indexOf(this.routeParams.idlparte_trabajo)
      return actual < this.lparteTrabajoList.length - 1
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Modificar material a instalar/facturar'
      this.lparteTrabajo = await this.$offline.lparteTrabajo.row(this.routeParams.idlparte_trabajo)
      if (this.routeQuery.extra && this.routeQuery.extra.ids) {
        this.lparteTrabajoList = this.routeQuery.extra.ids
      }
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async _submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      // fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      if (this.formData.fult_retimbrado && typeof(this.formData.fult_retimbrado) === 'string') {
        this.formData.fult_retimbrado = toDate(this.formData.fult_retimbrado)
      }
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      // fcaducidad
      // ffin_garantia
      if (this.formData.ffabricacion && typeof(this.formData.ffabricacion) === 'string') {
        this.formData.ffabricacion = toDate(this.formData.ffabricacion)
      }
      await Data.update(
        this,
        this.routeParams.idlparte_trabajo,
        this.formData,
      )
    },
    async submitForm () {
      await this._submitForm()
      await this.$dirty.modified(this.$dirty.ENTITIES.local.lparteTrabajo, this.routeParams.idlparte_trabajo)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.lparteTrabajoSubsis, this.lparteTrabajo.lparte_trabajo.idsubsis)
      this.$appRouter.go(-1)
    },
    async clickSiguienteAnteriorMaterial (siguiente) {
      const actual = this.lparteTrabajoList.indexOf(this.routeParams.idlparte_trabajo)
      let idlparteTrabajo = null
      if (siguiente) {
        if (actual < this.lparteTrabajoList.length - 1) {
          idlparteTrabajo = this.lparteTrabajoList[actual + 1]
        }
      } else {
        if (actual > 0) {
          idlparteTrabajo = this.lparteTrabajoList[actual - 1]
        }
      }
      if (idlparteTrabajo !== null) {
        await this._submitForm()
        this.$appRouter.replace({
          name: 'offline__lparte-trabajo-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idlparte_trabajo: idlparteTrabajo,
          },
          query: {
            extra: encodeExtraQuery(this.routeQuery.extra),
          },
        })
      }
    },
  },
}
</script>
